.b-footer {
  color: white;

  /* Font smoothing for white text on dark background */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &__columns {
    display: flex;
    flex-direction: column;

    @include mq.mq($from: $tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__column {
    margin-bottom: 4rem;
  }

  &__heading {
    font-weight: 600;
    font-size: $font-sm;
  }

  &__link {
    @include link-init(white, true);
    @include focus-visible(white);

    display: inline;
    line-height: 2em;

    &:focus {
      outline-color: white;
    }
  }

  &__text {
    font-size: $font-xs;
    margin-bottom: 0;
  }

  &__link-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 2.75rem;
    border-top: 0.5px solid #d8dada;
    padding-block: 2.75rem;

    @include mq.mq($from: $tablet) {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-top: 1.5rem;
      padding-bottom: 0;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__icon {
    display: inline-block;
    box-shadow: none;
  }

  &__icon-fb {
    width: 21px;
    height: 21px;
    background-image: svg-uri(
      '<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10.5" cy="10.5" r="10.25" fill="white" stroke="#E5E5E5" stroke-width="0.5"/><path d="M9.09673 15.6248H11.292V10.3118H12.7563L12.9502 8.48115H11.292L11.2944 7.56461C11.2944 7.08725 11.3397 6.83127 12.0247 6.83127H12.9401V5H11.4752C9.71551 5 9.09673 5.88849 9.09673 7.38203V8.48115H8V10.3124H9.09673V15.6248Z" fill="#333333"/></svg>'
    );
  }

  &__icon-in {
    width: 21px;
    height: 21px;
    background-image: svg-uri(
      '<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10.5" cy="10.5" r="10.25" fill="white" stroke="#E5E5E5" stroke-width="0.5"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15 14H13.0109V11.1877C13.0109 10.4516 12.6997 9.94902 12.0155 9.94902C11.4922 9.94902 11.2012 10.2882 11.0657 10.615C11.0149 10.7323 11.0228 10.8957 11.0228 11.0591V14H9.0522C9.0522 14 9.0776 9.01827 9.0522 8.56543H11.0228V9.41831C11.1392 9.04538 11.7689 8.51313 12.7738 8.51313C14.0205 8.51313 15 9.29488 15 10.9782V14ZM7.05938 7.88564H7.0467C6.4117 7.88564 6 7.47021 6 6.9436C6 6.40675 6.42387 6 7.07157 6C7.71873 6 8.11665 6.4057 8.12935 6.94204C8.12935 7.46865 7.71872 7.88564 7.05938 7.88564ZM6.22702 8.56543H7.98118V14H6.22702V8.56543Z" fill="#333333"/></svg>'
    );
  }

  &__icon-tw {
    width: 21px;
    height: 21px;
    background-image: svg-uri(
      '<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10.5" cy="10.5" r="10.25" fill="white" stroke="#E5E5E5" stroke-width="0.5"/><path d="M15.065 8.56594C15.065 8.46593 15.0625 8.36633 15.0584 8.26839C15.507 7.94428 15.8967 7.53882 16.2046 7.07775C15.7917 7.26077 15.3493 7.38361 14.8836 7.44005C15.3588 7.15536 15.7236 6.70467 15.895 6.16849C15.4509 6.4316 14.9583 6.62291 14.435 6.72583C14.0159 6.27888 13.4187 6 12.7576 6C11.4877 6 10.4585 7.0292 10.4585 8.29868C10.4585 8.47879 10.4792 8.65392 10.5187 8.82241C8.60843 8.72613 6.9144 7.81106 5.7802 6.42039C5.58225 6.76069 5.46936 7.15536 5.46936 7.57658C5.46936 8.37421 5.8744 9.07723 6.49192 9.49015C6.11469 9.47812 5.7607 9.37437 5.45069 9.20214V9.23119C5.45069 10.3455 6.24292 11.2734 7.2937 11.4855C7.10156 11.5386 6.89821 11.5664 6.68863 11.5664C6.54006 11.5664 6.39606 11.5519 6.25537 11.5249C6.54878 12.4371 7.39704 13.1031 8.403 13.1214C7.61699 13.7381 6.62556 14.1049 5.54822 14.1049C5.36188 14.1049 5.1797 14.0946 5 14.073C6.01675 14.7254 7.22565 15.1059 8.52377 15.1059C12.7522 15.1059 15.065 11.6029 15.065 8.56594Z" fill="#333333"/></svg>'
    );
  }

  &__logo {
    height: 1.5rem;
    width: 13rem;
    background-image: url($logo);
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: contain;

    @include underline(transparent);
  }

  &__logo-link {
    flex-grow: 1;

    @include link-init(transparent);
    @include mq.mq($until: $tablet) {
      width: 3rem;
    }
  }
}
