// Here it is necessary to use @use because of a collision in variable names
@use '../../../../../node_modules/sass-mq/mq' as mq;
@use '../../../../../node_modules/flexboxgrid-sass/flexboxgrid' as flexboxgrid;

@import '../../../../../node_modules/normalize-scss/sass/normalize';
@import '../../../../../node_modules/sass-svg-uri/svg-uri';

@tailwind base;
@tailwind components;
@tailwind utilities;

// Designsystem.
// Path to node_modules/@hdir/ui/dist is specified in tsup build file (client-asset.ts)
// and package.json watch:style, which is why it works to import directly on file name.
@import "index";
@import "hdir";

// The following stylesheets are old. Delete as they become obsolete
@import './settings';
@import './variables-helsedir';
@import './mixins';
@import './typography';
@import './spacing';
@import "./max-width";
@import './background-colors';
@import './headings';
@import './layout';
@import './animation';
@import './misc';
@import './video-wrapper';
@import './js';
@import './accessibility';
@import './suggestion';
@import './enonic_formbuilder';
@import './image';

// Temp styles. Remove your temp-styles after finishing your task.
@import './shame';

body {
  background-color: #fcfffc;
}

@import './print';
@import './print-pdf';

// Blocks:
@import 'blocks/b-loading';
@import 'blocks/b-alert';
@import 'blocks/b-input-search';
@import 'blocks/b-main-header';
@import 'blocks/b-nav-list';
@import 'blocks/b-page-meta';
@import 'blocks/b-article-intro';
@import 'blocks/b-link';
@import 'blocks/b-footer';
@import 'blocks/b-quote';
@import 'blocks/b-collapsible';
@import 'blocks/b-list';
@import 'blocks/b-select';
@import 'blocks/b-card';
@import 'blocks/b-box';
@import 'blocks/b-box-small';
@import 'blocks/b-list-item-type';
@import 'blocks/b-list-heading';
@import 'blocks/b-statistics';
@import 'blocks/b-press-release';
@import 'blocks/b-button';
@import 'blocks/b-square-image';
@import 'blocks/b-radio-button-group';
@import 'blocks/b-chapter-heading';
@import 'blocks/b-filter-list';
@import 'blocks/b-checkbox-group';
@import 'blocks/b-alert-bar';
@import 'blocks/b-transport-block';
@import 'blocks/b-icon';
@import 'blocks/b-link-list';
@import 'blocks/b-section-sidebar';
@import 'blocks/b-breadcrumbs';
@import 'blocks/b-multi-selector';
@import 'blocks/b-questionnaire';
@import 'blocks/b-long-short-heading';
@import 'blocks/b-input-text';
@import 'blocks/b-image';
@import 'blocks/b-hr';
@import 'blocks/b-search-tray';
@import 'blocks/b-table';
@import 'blocks/b-narrow-list';
@import 'blocks/b-secondary-logos';
@import 'blocks/b-flex';
@import 'blocks/b-product-search';
@import 'blocks/b-profession-picker';
@import 'blocks/b-lead';
@import 'blocks/b-toggle-content';
@import 'blocks/b-definition';
@import 'blocks/b-theme-box';
@import 'blocks/b-content-footer';
@import 'blocks/b-flex-links';
@import 'blocks/b-tags';
@import 'blocks/b-tabs';
@import 'blocks/b-grant-byline';
@import 'blocks/b-timeline';
@import 'blocks/b-color-info';
@import 'blocks/b-ninja';
@import 'blocks/b-meta-list';
@import 'blocks/b-icon-with-text';
@import 'blocks/b-presentation-links';
@import 'blocks/b-reference-list';
@import 'blocks/b-content-filter-search';
@import 'blocks/b-headings-overview';
@import 'blocks/b-related-statistics-list';
@import 'blocks/b-skip-to-content';
@import 'blocks/b-skip-to-next-heading';
@import 'blocks/b-transport-module';
@import 'blocks/b-report-frontpage';
@import 'blocks/b-chapter-hero';

// import custom styling from zoho
@import "./zoho/zoho-bundle";
