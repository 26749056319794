.b-main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(0.5vw + 1rem) 0;
  padding-top: calc(1.5vw + 1rem);
  border-bottom: 1px solid $grey-light;
  margin-bottom: $space-md;

  &--qa {
    border-width: 4px;
    border-bottom-style: solid;
    border-image: repeating-linear-gradient(
        -10deg,
        $orange,
        $orange 1px,
        transparent 1px,
        transparent 2px
      )
      1;
  }

  &__logo {
    height: 2.1rem;
    width: 18rem;
    background-image: url($logo);
    background-repeat: no-repeat;
    background-position-y: center;

    @include underline(transparent);

    @include mq.mq($until: $tablet) {
      height: 35px;
    }

    &--small {
      background-image: url($logo-small);
      background-size: contain;
      font-size: 0;
    }

    &--qa {
      height: 3rem;
      background-image: url($logo-qa);
    }
  }

  &__logo-link {
    @include link-init(transparent);

    @include mq.mq($until: $tablet) {
      width: 3rem;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__link {
    text-decoration: none;
    margin-right: 1.5rem;

    @include link-init($link-color);

    &:hover {
      @include underline($link-color);
    }
  }

  &__buttons-right {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin-left: 1rem;
  }

  /* Maintain tabbing order of menu listing while keeping buttons together */
  &__menu-wrapper {
    order: 1;
  }

  &__menu-underlay {
    position: fixed;
    background-color: rgb(0 0 0 / 13%);
    inset: 150px 0 0;
    z-index: 100;

    &--scrolled {
      top: 0;
    }
  }

  &__menu-overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    max-height: 100vh;
    overflow-y: scroll;
    z-index: 100;
    box-sizing: border-box;
    margin-top: 60px;
    left: 0;
    padding-inline: 4vw;
    width: 100vw;

    @include mq.mq($from: $desktop) {
      padding-inline: 6vw;
    }

    &--expanded {
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      background-color: #fcfffc;
    }
  }

  &__menu-overlay-header {
    width: 100%;
    max-width: 1150px;
    margin-inline: auto;
    display: flex;
    flex-direction: row;
    min-height: 2.4rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey-light;
    padding-top: calc(1.5vw + 1rem);
    padding-bottom: calc(0.5vw + 1rem);
  }

  &__menu-overlay-links {
    max-width: 1150px;
    margin-inline: auto;
    margin-top: -200vh;
    transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    padding-bottom: 4rem;

    &--expanded {
      margin-top: 0;
    }
  }
}

// Temp fix for wrongly used class
.l-container h1.b-main-header {
  border: 0;
  padding: 0;
}
