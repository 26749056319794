.b-link-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  // reset ul padding/margins
  padding-inline-start: 0;
  margin-block: 0 0;

  &--cols {
    &-4 {
      column-gap: 1%;
    }

    &-3 {
      column-gap: 5%;
    }

    &-2 {
      column-gap: 10%;
    }
    &-1 { }
  }

  &__link {
    @include focus-visible;
    @include link-init($link-color, false);
  }

  &__item {
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-bottom: 1px solid $link-color !important;

    &--cols {
      &-1 {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      &-2 {
        width: 45%;
      }

      &-3 {
        width: clamp(16rem, 30%, 9999px);

      }

      &-4 {
        width: clamp(10rem, 24%, 9999px);
      }
    }

    &:hover {
      // add conditional to let helfo use different hover color
      @if variable-exists($name: helfo-hover-light) {
        background-color: $helfo-hover-light;
      } @else {
        background-color: $grey-lighter;
      }

      .b-link-list__text {
        color: $link-color;

        @include underline($link-color);
      }
    }

    &--active {
      color: $grey;

      &:hover {
        background-color: transparent;

        .b-link-list__text {
          color: $grey;

          @include underline(transparent);
        }
      }
    }

    @include mq.mq($until: $tablet) {
      width: 100%;
      padding: 1rem;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
    height: 64px;
    width: auto;
  }

  &__text {
    font-weight: bold;
    font-size: $medium-large-text;
  }

  &__lead {
    display: block;
    color: $black;
    font-size: $font-xs;
  }
}
