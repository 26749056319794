.b-button {
  display: inline-block;
  color: white;
  background-color: $link-color;
  border: 2px solid $link-color;
  padding: $space-xs * 1.25 $space-md;
  border-radius: 2em;
  font-size: $font-body;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.1s ease-out;

  @include mq.mq($until: $tablet) {
    padding: $space-xs $space-md;
  }

  @include focus-visible;

  &:hover {
    background: transparent;
    color: $link-color;
  }

  &--download {
    &::after {
      @include pseudo-arrow($arrow-white-down);
    }
  }

  &--arrow {
    &::after {
      @include pseudo-arrow($arrow-white-right);
    }
  }

  &--small {
    font-size: $font-body;
  }

  &--clean {
    border: 0;
    background: transparent;
    color: $black;
    padding: 0;
    margin: 0 0 1.5em;
  }

  &--plain {
    border: 0;
    background: transparent;
    color: $black;
    text-decoration: underline;
  }

  &--secondary {
    border: 2px solid $link-color;
    color: $link-color;
    background: transparent;

    &.b-button--arrow {
      &::after {
        @include pseudo-arrow($arrow-right);
      }
    }

    &.b-button--download {
      &::after {
        @include pseudo-arrow($arrow-down);
      }
    }

    &:hover {
      background: $link-color;
      color: $white;
    }
  }

  &.b-button--search {
    &::after {
      @include pseudo-arrow($search);
    }

    &:hover::after {
      @include pseudo-arrow($search-white);
    }
  }

  &.b-button--hamburger {
    display: flex;
    align-items: center;

    &::before {
      @include icon-before($hamburger);
    }

    &:hover::before {
      @include icon-before($hamburger-white);
    }
  }

  &.b-button--hamburger-close {
    display: flex;
    align-items: center;

    &::before {
      @include icon-before($hamburger-close);
    }

    &:hover::before {
      @include icon-before($hamburger-close-white);
    }
  }

  &--secondary-dark {
    border: 2px solid $blue-dark;
    color: $blue-dark;
    background: transparent;

    &.b-button--arrow {
      &::after {
        @include pseudo-arrow($arrow-right);
      }

      &:hover::after {
        @include pseudo-arrow($arrow-white-right);
      }
    }

    &.b-button--download {
      &::after {
        @include pseudo-arrow($arrow-down);
      }

      &:hover::after {
        @include pseudo-arrow($arrow-white-down);
      }
    }

    &:hover {
      background: $blue-dark;
      color: $white;
    }
  }

  &__icon {
    display: inline-block;
    content: ' ';
    height: 1.6em;
    width: 1.2em;
    margin-right: 0.6em;
    margin-bottom: -0.5em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 center;
  }

  &__underline {
    text-decoration: none;
    box-shadow: 0 1px 0 $primary-color;
  }

  &__underline:hover {
    color: $primary-color;
  }

  &--option {
    &-primary,
    &-secondary {
      text-transform: uppercase;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
      color: $black;
    }

    &-primary {
      padding: 0.5em;
      background-color: $green-light;
      border-bottom: 1px solid $green-light;
    }

    &-secondary {
      padding: 0.5em 0;
      background-color: transparent;
      border-bottom: 1px solid $black;
    }
  }
}
