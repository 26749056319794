// For some reason default tailwind does not come with hdir tailwind so we
// recreate several default tailwind classes here

/** NOTES
- Tailwind does not add important so they are added manually as classes are used
- Backspace escapes following character allowing us to recreate classes like gap-2.5
*/
@layer base {
  .space-y-4 { @apply space-y-[16px]; }
  .space-y-8 { @apply space-y-[32px]; }
  .space-y-16 { @apply space-y-[64px]; }
  .mt-4 { @apply mt-[16px]; }
  .mt-16 { @apply mt-[64px]; }
  .mb-8 { @apply mb-[32px]; }
  .my-0 { @apply my-[0px]; }
  .my-8 { @apply my-[32px]; }
  .my-12 { @apply my-[48px]; }
  .px-4 { @apply px-[16px]; }
  .py-4 { @apply py-[16px]; }
  .px-6 { @apply px-[24px]; }
  .py-7 { @apply py-[28px]; }
  .top { @apply top-[12px]; }
  .w-32 { @apply w-[128px]; }
  .rounded-2xl { @apply rounded-[16px]; }
  .text-4xl { @apply text-[36px]; }
  .text-2xl { @apply text-[24px]; }
  .font-normal {@apply font-[400];}
  .border-b-2 { @apply border-b-[2px]; }
  .border-grey { @apply border-[#e1e1e1]; }
  .border-2 { @apply border-[2px]; }
  .divide-grey { @apply divide-[#e1e1e1]; }
  .p-2 { @apply p-[8px]; }
  .p-4 { @apply p-[16px]; }
  .p-6 { @apply p-[24px]; }
  .pt-10 { @apply pt-[40px] }
  .nt-hidden { display: none }

  .divide-y-2.divide-grey > * + * {
    border-top: 2px solid #e1e1e1 !important;
  }

  .border-b-2.border-grey{
    border-bottom: 2px solid #e1e1e1 !important;
  }
}

@layer utilities {
  .gap-24 { @apply gap-[96px]; }
  .gap-x-4 { @apply gap-x-[16px]; }
}

@layer components {
  .nt-p {
    @apply my-[20px] mx-[0px] max-w-[440px]
  }

  .bg-box {
    background-color: rgb(228, 240, 244);
  }

  .text-blue {
    color: rgb(0, 105, 232);
  }

  .text-bodyText {
    color: rgb(50, 50, 59);
  }

  .space-y-8>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(2rem* var(--tw-space-y-reverse));
    margin-top: calc(2rem*(1 - var(--tw-space-y-reverse)));
  }

  .overwrite-a {
    a:has(*) {
      @apply shadow-none;
    }

    a:not(:has(*)) {
      @apply text-[#0069e8];
    }
  }

  .nt-sidemenu {
    font-size: 15px;

    ul {
      list-style-type: none;
      padding: 0px;

      li {
        --tw-border-color: rgb(239, 239, 239);
        border-top: 1px solid var(--tw-border-color);
        border-bottom: 1px solid var(--tw-border-color);

        &:hover {
          --tw-border-color-hover: rgb(0, 105, 232);
          border-top: 1px solid var(--tw-border-color-hover);
          border-bottom: 1px solid var(--tw-border-color-hover);
          border-right: 0px !important;
          border-left: 0px !important;
          z-index: 10;
        }

        a {
          padding-top: 20px;
          padding-bottom: 20px;
          box-shadow: none;

          &:hover {
            border: none;
            color: black !important;
            font-weight: 500;
          }
        }
      }
    }
  }
}
