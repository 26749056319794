.background-wrapper {
  position: relative;
  height: 100%;
  padding: 3rem 0 5rem;

  &::after {
    background-image: url('../static/icon_background.svg');
    background-size: 1120px;
    background-repeat: repeat-x;
    content: '';
    opacity: 0.15;
    inset: 0;
    position: absolute;
    z-index: -1;
  }
}

table {
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    display: table-row;

    &:last-child {
      td {
        border-bottom: none;
      }

      th {
        border-bottom: none;
      }
    }
  }

  th,
  td {
    box-sizing: border-box;
    padding: $space-sm;
    display: table-cell;
    border: 0;
    border-bottom: 1px solid $black;
    border-right: 1px solid $black;

    &:first-child{
      border-bottom: 1px solid $black;
      border-right: 1px solid $black;
    }

    &:last-child {
      border-right: 0;
    }
  }

  th {
    text-align: left;
    font-weight: bold;
  }
}

.u-right-sm {
  margin-right: $space-sm;
}

.u-mb-xs {
  margin-bottom: $space-xs;
}

.hide {
  display: none;
}

.hide-mobile {
  @include mq.mq($until: tablet) {
    display: none;
  }
}

.l-mb-2-mobile {
  @include mq.mq($until: $tablet) {
    margin-bottom: 1.5em;
  }
}

.l-mt-0 {
  margin-top: 0 !important;
}

.frontpage-list {
  columns: 2;
}

.l-no-wrap-md {
  @media(width >= 768px) {
  flex-wrap: nowrap;
  }
}
